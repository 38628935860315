import { CircularProgress } from "@mui/material";

const LoadingItems = () => {
    return ( 
        <>
            <div className="w-full flex justify-center my-2">
                <CircularProgress size={32} style={{ color: '#FAC02B' }} />
            </div>
        </>
    );
}
 
export default LoadingItems;