import { Eye, EyeSlash } from "iconsax-react";
import { useRef, useState } from "react";
import DatePicker from "react-datepicker";

const TextInput = ({ type = "text", label, value, onChange, require, startIcon, placeholder, inputBoxClassName, ...other }) => {

	const inputRef = useRef();
	const [ShowPassword, setShowPassword] = useState(false)

	const handleChange = (e) => {
		if (type === "number") {
			const value = e.target.value;
			// Check if the value is a valid number
			if (!isNaN(value) || value === "" || value === ".") {
				// If it's a valid number, call onChange with the parsed float value
				onChange({ target: { value: value } });
			} else {
				// If it's not a valid number, you can handle it here, like displaying an error message
				console.error("Invalid number input");
			}
		} else {
			onChange(e);
		}
	};

	const handleFocusInput = () => {
		if (type === "date") {
			inputRef.current?.showPicker()
		}
	}

	const handleShowPassword = () => {
		if (type === "password") {
			setShowPassword(!ShowPassword)
		}
	}
	return (
		<div className="w-full flex flex-col gap-y-[6px]">
			{label && (
				<div className="flex items-center">
					<label className="leading-[20px] min-h-[20px] text-[14px]">{label || ""}</label>
					{require ? <span className="text-primary-main ml-2">{" *"}</span> : ""}
				</div>
			)}
			<div
				className={
					"w-full bg-black border-2 border-primary-borderColor rounded-3xl px-[14px] py-[8px] flex items-center justify-between gap-[2px]" +
					(inputBoxClassName ? ` ${inputBoxClassName}` : "")
				}>
				{startIcon && <div className="w-[20px]">{startIcon}</div>}
				{type === "date" ?
					<>
						<DatePicker
							className={`bg-transparent ${startIcon ? "w-[calc(100%-28px)] text-[16px]" : "w-full"} !shadow-none !outline-none placeholder-gray-500`}
							value={value}
							selected={value}
							onChange={handleChange}
							placeholderText={placeholder}
							ref={inputRef}
							dateFormat={"yyyy-MM-dd"}
							{...other}
						/>
					</>
					:
					<>
						<input
							className={`bg-transparent ${startIcon ? "w-[calc(100%-28px)] text-[16px]" : "w-full"} !shadow-none !outline-none placeholder-gray-500`}
							placeholder={placeholder}
							value={value}
							type={(type === "text" || type === "number" || ShowPassword) ? "text" : type}
							onChange={handleChange}
							// autoComplete="new-password"
							ref={inputRef}
							onFocus={handleFocusInput}
							{...other}
						/>
					</>
				}
				{type === "password" &&
					<>
						<div className="cursor-pointer">
							{ShowPassword ?
								<Eye size={19} onClick={handleShowPassword} />
								:
								<EyeSlash size={19} onClick={handleShowPassword} />
							}
						</div>
					</>
				}
			</div>
		</div>
	);
};
export default TextInput;
