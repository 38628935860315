import { Navigate, Outlet } from "react-router-dom";
import { routes } from "../../routes";
import { isAuthenticated } from "../../utils/auth";

const AuthLayout = () => {


    if (isAuthenticated()) {
        return <Navigate to={routes.dashboard.home} replace />
    }

    return (
        <>
            

            <Outlet />
        </>
    );
}

export default AuthLayout;