import { CircularProgress } from "@mui/material";

const Button = ({ 
	onClick, 
	children, 
	variant, 
	color, 
	className, 
	loading, 
	disabled, 
	loadingColor = "#fff" , 
	lodaingElement =  <CircularProgress size={15} style={{ color: loadingColor }} /> 

}) => {
	return (
		<button
			onClick={loading ? null : onClick}
			disabled={disabled || loading}
			className={`w-full flex items-center justify-center h-8 px-3 rounded-full  cursor-pointer
			${disabled ? "grayscale" : ""}
            ${
													variant === "filled" &&
													`text-gray-900 ${color === "pink" ? "bg-primary-pink" : color === "green" ? "bg-primary-green" : "bg-primary-main"}`
												}
            ${
													variant === "outlined" &&
													`text-white border ${
														color === "pink"
															? "border-primary-pink hover:bg-primary-pink hover:text-gray-900"
															: color === "green"
															? "border-primary-green hover:bg-primary-green hover:text-gray-900"
															: "border-primary-main hover:bg-primary-main hover:text-gray-900"
													}`
												}
            ${className}
        `}>
			{loading ? lodaingElement : children}
		</button>
	);
};
export default Button;
