import { setAuthToken } from "../services/axiosSetup";

export const setToken = (token) => {
    localStorage.setItem("rz_core_token" , token);
    setAuthToken(token);
}

export const getToken = () => {
    return localStorage.getItem("rz_core_token")
}

export const logoutUser = () => {
    localStorage.removeItem("rz_core_token");
    setAuthToken();
}

export const isAuthenticated = () => {
    const token = getToken();
    if(token){
        return true
    }
    return false
}