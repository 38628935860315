import { useEffect, useState } from "react";
import CustomSiteBox from "../../../components/customElement/customBox";
import { Copy } from "iconsax-react";
import { copyText } from "../../../utils";
import { getAllProjects } from "../../../services/dashboard";
import LoadingItems from "../../../components/loadingItem";
import NoData from "../../../components/noData";
import { Link } from "react-router-dom";

const DashboardHomePage = () => {

    const productArr = [
        {
            title: "CEX",
            value: "CEX",
        },
        {
            title: "DEX",
            value: "DEX",
        },
        {
            title: "Ranking",
            value: "Ranking",
        },
        {
            title: "Rayzon",
            value: "Rayzon",
        },
        {
            title: "Phygizon",
            value: "Phygizon",
        },
        {
            title: "MGC pro",
            value: "MGC pro",
        },
        {
            title: "Gamopedia",
            value: "Gamopedia",
        },
        {
            title: "Gamerspedia",
            value: "Gamerspedia",
        },
    ];

    const [SelectedProduct, setSelectedProduct] = useState(productArr[0])
    const [ProductsData, setProductsData] = useState({
        loading : true,
        data : {}
    })

    const handleGetProducts = () => {
        getAllProjects().then(({data}) => {
            setProductsData({
                loading : false,
                data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleCheckActiveProduct = (e , product) => {
        if(!product?.active){
            e.preventDefault();
        }
    }

    useEffect(() => {
        handleGetProducts();
    },[])

    return (
        <>

            <div className="max-w-[1240px] mx-auto mt-10">

                <CustomSiteBox className="w-full flex justify-between items-center">
                    <div className="text-[30px] font-semibold">Dashboard</div>
                    <div className="flex items-center gap-2">
                        <div className="text-primary-main text-[40px] font-bold">RZ</div>
                        <div className="text-[20px]">Blockchain</div>
                    </div>
                </CustomSiteBox>

                <div className="flex max-sm:flex-col gap-3 mt-6">
                    <CustomSiteBox className="md:w-[350px]">
                        <div className="text-[17px] mt-3">Wellcome Home!</div>
                        <div className="text-[17px] mt-3">onemohsen@gmail.com</div>
                        <div className="border border-[#707070] rounded-[10px] p-3 mt-6 overflow-hidden"
                            style={{
                                background: `linear-gradient(180deg, rgba(255, 255, 255, 0.122) 0%, rgba(0, 0, 0, 0.2) 100%)`
                            }}
                        >
                            <div className="">Your MGC Wallet Address</div>
                            <div className="mt-3">324 MGC</div>
                            <div className="w-full flex items-center mt-3 text-[#BABABA]">
                                <p className="flex-1 break-words w-[100px]">0x3b49349e1B29c2B323891b21667c0F7708C326b6</p>
                                <div className="cursor-pointer"
                                    onClick={() => copyText("0x3b49349e1B29c2B323891b21667c0F7708C326b6")}
                                >
                                    <Copy
                                        size="20"
                                    />
                                </div>
                            </div>
                        </div>
                    </CustomSiteBox>
                    <CustomSiteBox className="flex-1">
                        <div className="text-[30px] mt-3">Products & Services</div>
                        {ProductsData.loading ?
                            <>
                                <LoadingItems />
                            </>
                        :
                            <>
                                <div className="grid grid-cols-6 max-sm:grid-cols-2 max-lg:grid-cols-3  gap-4 mt-4">
                                    {ProductsData.data?.data?.map((product, index) =>
                                        <>
                                            <Link 
                                                to={product?.domain}
                                                className={
                                                    "font-semibold text-[16px] aspect-square rounded-lg border border-[#ddd] bg-[#131314] flex items-center justify-center cursor-pointer " +
                                                    (" hover:!bg-primary-main hover:text-[#131313]") + 
                                                    (!product?.active ? "!bg-gray-600 cursor-default hover:!bg-gray-600 hover:text-[#fff]" : "")
                                                    // (SelectedProduct?.value === product.value ? " !bg-primary-main text-[#131313]" : "")
                                                }
                                                // onClick={() => setSelectedProduct(product)}
                                                onClick={(e) => handleCheckActiveProduct(e , product)}
                                            >
                                                {product.title}
                                            </Link>
                                        </>
                                    )}
                                </div>

                                {ProductsData.data?.data?.length === 0 &&
                                    <>
                                        <NoData />
                                    </>
                                }
                            </>
                        }
                    </CustomSiteBox>
                </div>

            </div>


        </>
    );
}

export default DashboardHomePage;