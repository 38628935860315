const NoData = ({
    title = "No Data!"
}) => {
    return ( 
        <>
            <div className="flex w-full justify-center my-3">
                {title}    
            </div>  
        </>
    );
}
 
export default NoData;