import { useState } from "react";
import { toast } from "react-toastify";
import { emailReg, passwordReg } from "../../../utils/config";
import { loginUser, registerUser } from "../../../services/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import CustomSiteBox from "../../../components/customElement/customBox";
import TextInput from "../../../components/form/TextInput";
import Button from "../../../components/button/Button";
import VerifyAuth from "../verify";
import { generateSiteLink } from "../../../utils";

const RegisterPage = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [FormDataItems, setFormDataItems] = useState({
        email: "",
        password: ""
    })

    const [IsSubmitting, setIsSubmitting] = useState(false);
    const [VerifyMode, setVerifyMode] = useState({
        show : false,
        loginData : {},
        loginRes : {}
    })
    const [ShowAccsessModal, setShowAccsessModal] = useState(false)

    const handleAcceptPermission = () => {
        navigate(routes.dashboard.home)
    }

    const handleSubmitForm = () => {
        if (FormDataItems.email?.trim() === "") {
            toast.error("Please Fill Your Email!");
            return
        }
        // if (!emailReg.test(FormDataItems.email.trim())) {
        //     toast.error("Your Email Is Invalid!");
        //     return
        // }
        if (FormDataItems.password?.trim() === "") {
            toast.error("Please Fill Your Password!");
            return
        }
        if (!passwordReg.test(FormDataItems.password.trim())) {
            toast.error("Your Password Is Weak!");
            return
        }

        const sendData = {
            email: FormDataItems.email,
            password: FormDataItems.password,
        }

        if(searchParams.get("redirect")){
            //sendData.domain = searchParams.get("redirect");
            sendData.domain = "http://gamopedia.com";
        }


        setIsSubmitting(true);

        registerUser(sendData).then(({ data }) => {
            setIsSubmitting(false);

            setVerifyMode({
                show : true,
                loginData : sendData,
                loginRes : data
            })
            // setShowAccsessModal(true)
            // navigate(routes.dashboard.home)
        }).catch(err => {
            console.log(err)
            setIsSubmitting(false);
        })


    }



    return (
        <>
            <CustomSiteBox className="max-w-[150px] text-center mx-auto mb-3 mt-10"
                style={{
                    background: `linear-gradient(#002773, #002773) padding-box,linear-gradient(to top, #fff, #002773) border-box`
                }}
            >
                <div className="text-[40px] font-bold">RZ</div>
                <div className="text-[18px] mt-3">Blockchain</div>
            </CustomSiteBox>

            {(!ShowAccsessModal && !VerifyMode?.show) &&
                <>
                    <CustomSiteBox className="max-w-[500px] mx-auto">
                        <div className="text-center text-[21px] mb-4">Sign Up</div>


                        <div className="flex flex-col gap-3">

                            <TextInput
                                label={"Email"}
                                placeholder={"Type the ID you like..."}
                                value={FormDataItems?.email}
                                onChange={e => setFormDataItems({ ...FormDataItems, email: e.target.value })}
                            />

                            <div className="">
                                <TextInput
                                    label={"Password"}
                                    placeholder={"Enter your hard pass code"}
                                    type="password"
                                    value={FormDataItems?.password}
                                    onChange={e => setFormDataItems({ ...FormDataItems, password: e.target.value })}
                                />

                                {/* <div className="">
                                    <div className="opacity-70">Use </div>
                                </div> */}

                            </div>


                        </div>

                        <div className="flex justify-center mt-5">
                            <Button
                                onClick={handleSubmitForm}
                                loading={IsSubmitting}
                                variant={"filled"}
                                className={"!w-[130px]"}
                                color="green"
                            >
                                Sign up
                            </Button>
                        </div>

                        <div className="flex gap-2 items-center justify-center mt-3">
                            <div>Already have an account?</div>
                            <Link to={generateSiteLink("/login" , searchParams)} className="text-primary-main" >Login</Link>
                        </div>



                    </CustomSiteBox>
                </>
            }

            {(ShowAccsessModal) &&
                <>
                    <CustomSiteBox className="max-w-[500px] mx-auto text-center">
                        <div className="text-[22px] mb-2">Authorization Request</div>
                        <div className="">
                            <span className="text-primary-main">Ranking </span>
                            <span>is requesting permission to access your account.</span>
                        </div>

                        <div className="flex gap-3 items-center mt-8">
                            <Button
                                onClick={handleAcceptPermission}
                                loading={IsSubmitting}
                                variant={"filled"}
                                className={"!flex-1"}
                                color="green"
                            >
                                Accept & Continue
                            </Button>
                            <Button
                                onClick={() => setShowAccsessModal(false)}
                                loading={IsSubmitting}
                                variant={"outlined"}
                                className={"!flex-1"}
                                color="pink"
                            >
                                Cancel
                            </Button>
                        </div>
                    </CustomSiteBox>
                </>
            }

            {VerifyMode.show &&
                <>
                    <VerifyAuth 
                        loginData={VerifyMode.loginData}
                        loginRes={VerifyMode.loginRes}
                    />
                </>
            }
        </>
    );
}

export default RegisterPage;