import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "./auth";
import { routes } from "../routes";

const PrivateRoute = () => {
    
    if (isAuthenticated()) {
        return <Outlet />;
    }

    return <Navigate to={routes.auth.login} replace />;
    // return <Outlet />;
};

export default PrivateRoute;