const CustomSiteBox = ({
    children,
    className = "",
    other,
}) => {
    return (
        <>

            <div 
                className={`rounded-[10px] p-5 ${className}`}
                style={{
                    background: `linear-gradient(179.4deg, rgba(53, 53, 53, 0.5) 1.26%, rgba(0, 0, 0, 0) 104.49%)`
                }}
                {...other}
            >
                {children}
            </div>

        </>
    );
}

export default CustomSiteBox;