/* eslint-disable no-unused-vars */
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
// import Header from "../header";
// import Sidebar from "../sidebar";
import { useEffect, useState } from "react";
// import SiteModal from "../siteModal";
// import { verifyOrder } from "../../service/cart";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
// import { getUserCartAction } from "../../store/action/cart_action";
// import { routes } from "../../routes";

const AppLayout = () => {

    // const dispatch = useDispatch();
    const navigate = useNavigate();

    let [searchParams] = useSearchParams();



    return (
        <>
            <div className="max-w-[1240px] mx-auto px-[20px]">
                {/* <div className="row">
                    <div className="sideBarMainBoxSize">
                        <Sidebar />
                    </div>
                    <div className="mainContentBox">

                        <Header />


                    </div>
                </div> */}
                <Outlet />
            </div>
        </>
    );
}

export default AppLayout;