import { toast } from "react-toastify";

export const separateNumberWithComma = (myNumber) => {

    if (!myNumber && myNumber !== 0) return

    return myNumber
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function groupBy(arr, getKey) {
    return arr.reduce((acc, item) => {
        const key = getKey(item);
        acc[key] = acc[key] || [];
        acc[key].push(item);
        return acc;
    }, {});
}

export const generateRedirectLink = (searchParams = {} , token) => {
    let link = `${searchParams?.get("redirect")}${searchParams?.get("login_page") ? searchParams?.get("login_page") : "/login"}?token=${token}`;
    for (let entry of searchParams.entries()) {

        if(entry[0] === "redirect" || entry[0] === "login_page") continue
        
        link += `&${entry[0]}=${entry[1]}`
    }
    return link
}

export const generateSiteLink = (pageLink = "/" , searchParams = {}) => {
    let link = `${pageLink}?${searchParams?.get("redirect") ? `redirect=${searchParams?.get("redirect")}` : ""}`;

    for (let entry of searchParams.entries()) {

        if(entry[0] === "redirect") continue
        
        link += `&${entry[0]}=${entry[1]}`
    }
    return link
}

export const copyText = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = text;
    // Make the textarea out of view but still in the DOM to allow for selection
    textarea.style.position = "fixed";
    textarea.style.opacity = 0;
    document.body.appendChild(textarea);

    // Select the text inside the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999);

    // Copy the selected text
    navigator.clipboard.writeText(textarea.value);

    // Clean up: remove the textarea from the DOM
    document.body.removeChild(textarea);

    // Inform the user that the text has been copied
    toast.info("Copied!", { autoClose: 5000 });
    // console.log({text})
    // const textarea = document.createElement("textarea");
    // textarea.value = text;
    // document.body.appendChild(textarea);

    // textarea.select();
    // textarea.setSelectionRange(0, 99999);

    // document.execCommand("copy");

    // document.body.removeChild(textarea);

    // toast.info("Copied!");
};
