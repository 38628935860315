/* eslint-disable no-unused-vars */
import { useState } from "react";
import CustomSiteBox from "../../../components/customElement/customBox";
import Button from "../../../components/button/Button";
import { toast } from "react-toastify";
import { verifyUser } from "../../../services/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import { setAuthToken } from "../../../services/axiosSetup";
import VerificationInput from "react-verification-input";
import { setToken } from "../../../utils/auth";
import { generateRedirectLink } from "../../../utils";

const VerifyAuth = ({
    loginData = {},
    loginRes = {},
}) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [FormDataItems, setFormDataItems] = useState({
        code: ""
    })
    const [IsSubmitting, setIsSubmitting] = useState(false);

    const handleSubmitForm = () => {
        if (FormDataItems.code?.toString()?.trim() === "") {
            toast.error("Please Enter Code!")
            return
        }

        console.log({loginRes})

        let senData = {
            code: FormDataItems.code,
            token: loginRes?.data?.token
        }

        setIsSubmitting(true)

        verifyUser(senData).then(({ data }) => {
            // setAuthToken()
            //setToken("smlkmsldkl");
            if (loginData?.domain) {
                //window.location.href = `${loginData?.domain}?rz_tk=klskldn`
                window.location.href = generateRedirectLink(searchParams , data?.data?.accessToken)
                // window.location.href = searchParams.get("redirect") + `/login?token=${data?.data?.accessToken}`
            } else {
                navigate(routes.dashboard.home)
            }
            setIsSubmitting(false)
        }).catch((err) =>{
            console.log(err)
            setIsSubmitting(false)
        })
    }

    return (
        <>
            <CustomSiteBox className="max-w-[500px] mx-auto">
                <div className="text-center text-[21px] mb-4">Verify</div>


                <div className="flex flex-col gap-3 my-3">

                    <div className="capitalize leading-7 text-center text-[17px] font-medium mb-3">
                        a verify code send to your email <br />
                        please enter code
                    </div>


                    <div className="flex justify-center">
                        <VerificationInput
                            length={4}
                            validChars="0-9"
                            classNames={{
                                container: "verifyInputsBox_container",
                                character: "verifyInputsBox_character",
                                characterInactive: "verifyInputsBox-character--inactive",
                                characterSelected: "verifyInputsBox-character--selected",
                                characterFilled: "verifyInputsBox-character--filled",
                            }}
                            onChange={value => setFormDataItems({...FormDataItems , code : value})}

                        />
                    </div>



                </div>

                <div className="flex justify-center mt-5">
                    <Button
                        onClick={handleSubmitForm}
                        loading={IsSubmitting}
                        variant={"filled"}
                        className={"!w-[130px]"}
                        color="green"
                    >
                        Verify
                    </Button>
                </div>



            </CustomSiteBox>
        </>
    );
}

export default VerifyAuth;