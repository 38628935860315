import { Navigate, createBrowserRouter } from "react-router-dom";
import LoginPage from "../pages/auth/login";
import AuthLayout from "../components/layout/authLayout";
import DashboardHomePage from "../pages/dashboard/home";
import AppLayout from "../components/layout/appLayout";
import PrivateRoute from "../utils/privateRoutes";
import RegisterPage from "../pages/auth/register";
import ForgetPasswordPage from "../pages/auth/forgetPassword";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {element : <Navigate to={"/login"} replace />},
          {
            path: "/login",
            element: <LoginPage />,
          },
          {
            path: "/register",
            element: <RegisterPage />,
          },
          {
            path: "/forget-password",
            element: <ForgetPasswordPage />,
          },
        ]
      },
      {
        path: "",
        element: <PrivateRoute />,
        children:[
          {
            path: "/",
            element: <DashboardHomePage />,
          }
        ]
      },

    ],
  },

]);

export default router;
