/* eslint-disable array-callback-return */
import axios from "axios";
import { toast } from "react-toastify";
// import { routes } from "../routes";
import { apiUrl } from "../utils/config";
import { logoutUser } from "../utils/auth";
import { routes } from "../routes";

const customAxios = axios.create({
    baseURL: apiUrl
});

const setAuthToken = (token) => {
    if (token) {
        customAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete customAxios.defaults.headers.common['Authorization'];
    }
};

setAuthToken(localStorage.getItem("rz_core_token"));

customAxios.defaults.headers.post["Content-Type"] = "application/json";


customAxios.interceptors.response.use(null, (error) => {

    if (error.response) {

        if(error.response.status === 401){
            logoutUser();
            // navigate(routes.auth.login);
            window.location.href = routes.auth.login;
        }

        var myObject = error.response.data["errors"];
        if(myObject){
            Object.keys(myObject).map(function (key) {
                var text = myObject[key][0];
                toast.error(text, {
                    closeOnClick: true,
                });
            });
        }else{
            toast.error(error.response.data["message"] || error.response.data["error"], {
                closeOnClick: true,
            });
        }
        
    } else {
        toast.error("Server Error", {
            closeOnClick: true,
        });
    }
    const expectedErrors =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    if (!expectedErrors) {
        // setTimeout(()=>{toast.dismiss("loadingToast")},700)

        // localStorage.removeItem("token");
    }
    // return Promise.reject(error);
    if (error.response) {
        return Promise.reject(error.response);
    } else {
        return Promise.reject(error.message);
    }
});

export {customAxios , setAuthToken};