import { useState } from "react";
import CustomSiteBox from "../../../components/customElement/customBox";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import TextInput from "../../../components/form/TextInput";
import { toast } from "react-toastify";
import { emailReg, passwordReg } from "../../../utils/config";
import { forgetPassword, updatePassword } from "../../../services/auth";
import Button from "../../../components/button/Button";
import VerificationInput from "react-verification-input";
import { generateSiteLink } from "../../../utils";

const ForgetPasswordPage = () => {


    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [IsSubmitting, setIsSubmitting] = useState(false);


    const [FormDataItems, setFormDataItems] = useState({
        email: "",
        code: "",
        password: "",
    })

    const [ResData, setResData] = useState({})

    const [FormStep, setFormStep] = useState(1);

    const handleSendEmail = () => {
        if (FormDataItems.email?.trim() === "") {
            toast.error("Please Fill Your Email!");
            return
        }
        // if (!emailReg.test(FormDataItems.email.trim())) {
        //     toast.error("Your Email Is Invalid!");
        //     return
        // }
        const sendData = {
            email: FormDataItems.email,
        }

        setIsSubmitting(true);

        forgetPassword(sendData).then(({ data }) => {
            setIsSubmitting(false);
            setResData(data?.data);
            setFormStep(2)
        }).catch(err => {
            console.log(err)
            setIsSubmitting(false);
        })

    }

    const handleUpdatePassword = () => {
        if (FormDataItems.code?.toString()?.trim() === "") {
            toast.error("Please Enter Code!")
            return
        }
        if (FormDataItems.password?.trim() === "") {
            toast.error("Please Fill Your Password!");
            return
        }

        if (!passwordReg.test(FormDataItems.password.trim())) {
            toast.error("Your Password Is Weak!");
            return
        }
       
        const sendData = {
            token: ResData?.token,
            code: FormDataItems.code,
            password: FormDataItems.password?.trim(),
        }

        setIsSubmitting(true);

        updatePassword(sendData).then(({ data }) => {
            toast.success("Please Login To Your Account!")
            setIsSubmitting(false);
            // `/login${searchParams.get("redirect") ? `?redirect=${searchParams.get("redirect")}` : "" }`
            navigate(
                generateSiteLink("/login" , searchParams)
            )
            // if(searchParams.get("redirect")){
            // }
        }).catch(err => {
            console.log(err)
            setIsSubmitting(false);
        })

    }

    return (
        <>

            <CustomSiteBox className="max-w-[150px] text-center mx-auto mb-3 mt-10"
                style={{
                    background: `linear-gradient(#002773, #002773) padding-box,linear-gradient(to top, #fff, #002773) border-box`
                }}
            >
                <div className="text-[40px] font-bold">RZ</div>
                <div className="text-[18px] mt-3">Blockchain</div>
            </CustomSiteBox>

            <CustomSiteBox className="max-w-[500px] mx-auto">

                {FormStep === 1 &&
                    <>


                        <div className="text-center text-[21px] mb-4">Forget Password</div>


                        <div className="flex flex-col gap-3">

                            <TextInput
                                label={"Email"}
                                name={"email"}
                                placeholder={"Type Your Email..."}
                                value={FormDataItems?.email}
                                onChange={e => setFormDataItems({ ...FormDataItems, email: e.target.value })}
                                key={`forgetEmail`}
                            />

                            {/* <TextInput
                                label={"Password"}
                                placeholder={"Enter your hard pass code"}
                                type="password"
                                value={FormDataItems?.password}
                                onChange={e => setFormDataItems({ ...FormDataItems, password: e.target.value })}
                            /> */}

                        </div>

                        <div className="flex justify-center mt-5">
                            <Button
                                onClick={handleSendEmail}
                                loading={IsSubmitting}
                                variant={"filled"}
                                className={"!w-[130px]"}
                                color="green"
                            >
                                Send Email
                            </Button>
                        </div>

                    </>
                }

                {FormStep === 2 &&
                    <>


                        <div className="text-center text-[21px] mb-4">Update Password</div>


                        <div className="flex flex-col gap-3">

                            <div className="capitalize leading-7 text-center text-[17px] font-medium mb-3">
                                a verify code send to your email <br />
                                please enter code
                            </div>

                            <div className="flex justify-center">
                                <VerificationInput
                                    length={4}
                                    validChars="0-9"
                                    classNames={{
                                        container: "verifyInputsBox_container",
                                        character: "verifyInputsBox_character",
                                        characterInactive: "verifyInputsBox-character--inactive",
                                        characterSelected: "verifyInputsBox-character--selected",
                                        characterFilled: "verifyInputsBox-character--filled",
                                    }}
                                    onChange={value => setFormDataItems({ ...FormDataItems, code: value })}
                                    key={`updatePassCode`}
                                    inputProps={{
                                        name : "verify code"
                                    }}
                                />
                            </div>

                            <TextInput
                                label={"New Password"}
                                placeholder={"Enter your hard pass code"}
                                name={"password"}
                                type="password"
                                value={FormDataItems?.password}
                                onChange={e => setFormDataItems({ ...FormDataItems, password: e.target.value })}
                                key={`forgetPassword`}
                            />

                        </div>

                        <div className="flex justify-center mt-5">
                            <Button
                                onClick={handleUpdatePassword}
                                loading={IsSubmitting}
                                variant={"filled"}
                                className={"!w-[160px]"}
                                color="green"
                            >
                                Update Password
                            </Button>
                        </div>



                    </>
                }


                <div className="flex gap-2 items-center justify-center mt-3">
                    <div>Back To Sign In?</div>
                    {/* <Link to={searchParams.get("redirect") ? `/login?redirect=${searchParams.get("redirect")}` : "/login"} className="text-primary-main" >Sign In</Link> */}
                    <Link to={generateSiteLink("/login" , searchParams)} className="text-primary-main" >Sign In</Link>
                </div>




            </CustomSiteBox>



        </>
    );
}

export default ForgetPasswordPage;