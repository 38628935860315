import { customAxios } from "./axiosSetup";

export const loginUser = (data) => customAxios.post("/user/login" , data);

export const registerUser = (data) => customAxios.post("/user/signup" , data);

export const verifyUser = (data) => customAxios.post("/user/verify" , data);

export const forgetPassword = (data) => customAxios.post("/user/forgot-password" , data);

export const updatePassword = (data) => customAxios.put("/user/forgot-password" , data);

export const getUserData = () => customAxios.get("/user/get_profile");